import axios, { AxiosError, AxiosPromise, CancelTokenSource } from "axios";
import { saveAs } from "file-saver";
import { Feature } from "geojson";
import qs from "qs";
import { identity, isNil, pathOr, reject, zip } from "ramda";
import { JsonDecoder } from "ts.data.json";
import { logout } from "./slices/auth";
import {
  adminCaseWithImagesAndReadersDecoder,
  annotationDecoder,
  apiResponseDecoder,
  caseAndCountsArrayDecoder,
  caseWithImagesDecoder,
  configDecoder,
  imageArrayDecoder,
  imageDecoder,
  imageListViewArrayDecoder,
  imageManagementCaseRecordArrayDecoder,
  imageManagementRecordArrayDecoder,
  imageWithAnnotationsDecoder,
  organizationsDecoder,
  readersStudyStatsDecoder,
  rolesDecoder,
  studiesAccessDecoder,
  studyDecoder,
  studyListViewsDecoder,
  studyViewDecoder,
  userDecoder,
  userSummariesDecoder,
  usersDecoder,
  usersInStudyDecoder,
  sampleDataImportResultsDecoder,
  sampleDataSignedURLDecoder,
  getDownloadResponseDecoder,
  queryMetadataDecoder,
  caseSummariesDecoder,
  studiesDecoder,
  queryDetailsArrayDecoder,
  queryStatsDecoder,
  querySearchDataDecoder
} from "./decoders";
import {
  AdminCaseWithImagesAndReaders,
  Annotation,
  ApiResponse,
  CasesAndCounts,
  CaseStatus,
  CaseSummaries,
  CaseWithImages,
  Config,
  DateFilter,
  formatCaseStatus,
  GetDownloadResponse,
  Image,
  ImageListViews,
  ImageManagementCaseRecords,
  ImageManagementRecords,
  Images,
  ImageStatusType,
  ImageWithAnnotations,
  MetadataImage,
  MetadataImageType,
  Organizations,
  QueryObjectType,
  QuerySearchDataRecords,
  QueryStats,
  ReadersStudyStats,
  ReportStudyData,
  ReportUserData,
  Roles,
  SampleDataImportResults,
  SampleDataSignedURL,
  SampleDataUploadResponse,
  SignedURLs,
  Studies,
  StudiesAccess,
  Study,
  StudyId,
  StudyListViews,
  StudyView,
  UpdateValueWithMaybeReasonForChange,
  UpdateValueWithReasonForChange,
  User,
  Users,
  UsersInStudy,
  UserSummaries,
  UUID
} from "./models";
import { idsOnly, LOCALE, updateWithIdsOnly } from "./utils";
import { QueryFilter } from "./slices/queries";
import { ReportType } from "./slices/reports";
import { StudyFormFields } from "./slices/studyConfiguration";
import { AppDispatch } from "./store";

// eslint-disable-next-line
let dispatch: AppDispatch;

export const injectStoreDispatchInApi = (_dispatch: AppDispatch) => {
  dispatch = _dispatch;
};

const customAxios = axios.create({
  // This custom serializer makes our query params adhere to the format the backend expects for repeated parameters: <origin>?id=1&id=2&id=3
  paramsSerializer: params => qs.stringify(reject(isNil, params), { arrayFormat: "repeat" })
});

customAxios.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401 && window.location.pathname !== "/login") {
    dispatch(logout("/login?redirect=" + window.location.pathname));
  } else {
    return Promise.reject(error);
  }
});

// Use with pathOr to drill down into an error message when present
const errorMsgPath: ReadonlyArray<string> = ["response", "data", "message"];

async function decodeResponse<T>(decoder: JsonDecoder.Decoder<T>, responseData: any): Promise<T> {
  return decoder.decodePromise(responseData);
}

//this is configured to retrieve binary for a pdf
const customAxiosBlob = axios.create({
  responseType: "blob",
  // This custom serializer makes our query params adhere to the format the backend expects for repeated parameters: <origin>?id=1&id=2&id=3
  paramsSerializer: params => qs.stringify(reject(isNil, params), { arrayFormat: "repeat" })
});

export function fetchHisto(path: string, params: object = {}): AxiosPromise {
  return customAxios.get(path, {
    params
  });
}
export function fetchHistoBlob(path: string, params: object = {}): AxiosPromise {
  return customAxiosBlob.get(path, {
    params
  });
}

export function putHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.put(path, data);
}

export function postHisto(path: string, data: object): AxiosPromise {
  return customAxios.post(path, data);
}

export function patchHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.patch(path, data);
}

export function deleteHisto(path: string, data: object = {}): AxiosPromise {
  return customAxios.delete(path, data);
}

export async function fetchUser(): Promise<User> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users/info")
      .then(response =>
        decodeResponse<User>(userDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Please log in", errorMsgPath, error)));
  });
}

export async function fetchAvailableUserRoles(): Promise<Roles> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users/availableRoles")
      .then(response =>
        decodeResponse<Roles>(rolesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Error switching roles: ", errorMsgPath, error)));
  });
}

export async function updateWorkAsApi(roleId: UUID): Promise<User> {
  return new Promise((resolve, reject) => {
    patchHisto("/api/users/workAs", {
      roleId
    })
      .then(response =>
        decodeResponse<User>(userDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Error switching roles: ", errorMsgPath, error)));
  });
}

export async function fetchStudies(name?: string, protocolId?: string): Promise<StudyListViews> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/studies", { name, protocolId })
      .then(response =>
        decodeResponse(studyListViewsDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch studies", errorMsgPath, error)));
  });
}

export async function fetchQueryStats(): Promise<QueryStats> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/queries/stats", {})
      .then(response =>
        decodeResponse(queryStatsDecoder, response.data.stats).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch query stats for user", errorMsgPath, error)));
  });
}

export async function fetchStudiesForUser(name?: string): Promise<Studies> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/studies/list", { name })
      .then(response => decodeResponse(studiesDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to fetch studies for user", errorMsgPath, error)));
  });
}

export async function createStudy(
  name: StudyFormFields["name"],
  sponsor: StudyFormFields["sponsor"],
  protocolIds: StudyFormFields["protocolIds"],
  visitIds: StudyFormFields["visitIds"],
  indications: StudyFormFields["indications"],
  segments: StudyFormFields["segments"],
  anatomicalSegments: StudyFormFields["anatomicalSegments"],
  modality: StudyFormFields["modality"],
  uploaders: StudyFormFields["uploaders"],
  readers: StudyFormFields["readers"],
  onHold: StudyFormFields["onHold"],
  onHoldReason: StudyFormFields["onHoldReason"],
  hpfAnnotationClasses: StudyFormFields["hpfAnnotationClasses"],
  pointAnnotationClasses: StudyFormFields["pointAnnotationClasses"],
  freehandAnnotationClasses: StudyFormFields["freehandAnnotationClasses"]
): Promise<Study> {
  return new Promise((resolve, reject) => {
    postHisto("/api/studies", {
      name,
      sponsor,
      protocolIds,
      visitIds,
      indications,
      segments,
      anatomicalSegments,
      modality,
      uploaders: idsOnly(uploaders),
      readers: idsOnly(readers),
      readonlyUsers: [],
      onHold,
      onHoldReason,
      hpfAnnotationClasses,
      pointAnnotationClasses,
      freehandAnnotationClasses
    })
      .then(response => decodeResponse(studyDecoder, response.data).then(resolve).catch(reject))
      .catch(error => {
        return reject(pathOr("Unable to create study", errorMsgPath, error));
      });
  });
}

export async function createSampleDataSignedUrl(file: File): Promise<SampleDataUploadResponse> {
  const fileName: string = file?.name || "";
  return new Promise((resolve, reject) => {
    postHisto("/api/samples/upload", {
      fileName: fileName
    })
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        return reject(pathOr("Unable to create sample data signed URLs", errorMsgPath, error));
      });
  });
}

export async function createSignedURLs(studyId: string, files: FileList): Promise<SignedURLs> {
  return new Promise((resolve, reject) => {
    postHisto("/api/signed-urls", {
      studyId: studyId,
      fileNames: files !== null ? Array.from(files).map(file => file.name) : []
    })
      .then(response => {
        return resolve(response.data.urls);
      })
      .catch(error => {
        return reject(pathOr("Unable to create signed URLs", errorMsgPath, error));
      });
  });
}

export async function createCase(
  studyId: UUID,
  procId: string,
  subjectId: string,
  visitId: string,
  siteId: string | null,
  readers: ReadonlyArray<UUID>,
  images: ReadonlyArray<UUID>
): Promise<AdminCaseWithImagesAndReaders> {
  return new Promise((resolve, reject) => {
    postHisto("/api/cases", {
      studyId,
      procId,
      subjectId,
      visitId,
      siteId,
      readers,
      images
    })
      .then(response =>
        decodeResponse(adminCaseWithImagesAndReadersDecoder, response.data)
          .then(resolve)
          .catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create case", errorMsgPath, error));
      });
  });
}

function urlToPathname(url: string): string {
  return new URL(url).pathname.slice(1, url.length); // Remove leading slash from pathname
}

export function sendNewFilesNotification(
  studyId: StudyId,
  numFiles: number,
  failures: boolean
): Promise<void> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images/notify-upload`, {
      studyId,
      numFiles,
      failures
    })
      .then(_ => resolve())
      .catch(error => {
        return reject(pathOr("Unable to send new files notification", errorMsgPath, error));
      });
  });
}

export async function importSampleData(
  sampleDataRequest: SampleDataSignedURL
): Promise<SampleDataImportResults> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/samples/process`, sampleDataRequest)
      .then(response => {
        return decodeResponse(sampleDataImportResultsDecoder, response.data)
          .then(resolve)
          .catch(reject);
      })
      .catch(error => {
        return reject(pathOr("Unable to import sample data", errorMsgPath, error));
      });
  });
}

export function createImage(
  studyId: StudyId,
  file: File,
  url: string,
  imageToReplace: Image | null
): Promise<Image> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images`, {
      name: file.name,
      s3Key: urlToPathname(url),
      studyId,
      imageToReplaceId: imageToReplace ? imageToReplace.id : null
    })
      .then(response =>
        decodeResponse(imageDecoder, response.data.image).then(resolve).catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create image", errorMsgPath, error));
      });
  });
}

export function createAnnotationApi(
  geometry: Feature,
  radiusX: number | null,
  radiusY: number | null,
  tilt: number | null,
  text: string | null,
  annotationClassId: UUID | null,
  annotationType: string | null,
  imageId: UUID
): Promise<Annotation> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/annotations`, {
      geometry,
      radiusX,
      radiusY,
      // Adjust tilt so that ellipses display correctly after saving. It's
      // unclear why during editing the tilt is off by 90 degrees, but this
      // seems to fix it.
      tilt: tilt ? tilt + 90 : null,
      text,
      annotationClassId,
      annotationType,
      imageId
    })
      .then(response =>
        decodeResponse(annotationDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to create annotation", errorMsgPath, error));
      });
  });
}

export function deleteAnnotationApi(annotationId: UUID, deleteNested: boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    deleteHisto(`/api/annotations/${annotationId}?deleteNested=${deleteNested}`, {})
      .then(_ => resolve())
      .catch(error => {
        return reject(pathOr("Unable to delete annotation", errorMsgPath, error));
      });
  });
}

export const generateCancelTokenSource = (): CancelTokenSource => axios.CancelToken.source();

function makeUploadSampleDataPromises(
  //dispatch: AppDispatch,
  sampleDataFile: File,
  sdur: SampleDataUploadResponse,
  cancelTokenSource: CancelTokenSource
): ReadonlyArray<Promise<SampleDataSignedURL>> {
  if (sampleDataFile) {
    return zip([sampleDataFile], [sdur.putSignedUrl]).map(([file, url]) => {
      return new Promise((resolve, reject) => {
        customAxios
          .put(url, file, {
            cancelToken: cancelTokenSource.token,
            headers: {
              "Content-Type": file.type
            }
          })
          .then(response => {
            return decodeResponse(sampleDataSignedURLDecoder, response.data)
              .then(resolve)
              .catch(reject);
          })
          .catch((thrown: AxiosError) => {
            axios.isCancel(thrown)
              ? reject(`Cancelled upload of ${file.name} to ${url}`)
              : reject(`Error uploading ${file.name} to ${url}`);
          });
      });
    });
  } else {
    return [];
  }
}

function makeUploadPromises(
  dispatch: AppDispatch,
  studyId: StudyId,
  files: FileList,
  urls: SignedURLs,
  progressCallback: (dispatch: AppDispatch, file: File) => (progressEvent: ProgressEvent) => void,
  imageToReplace: Image | null,
  cancelTokenSource: CancelTokenSource
): ReadonlyArray<Promise<Image>> {
  return zip(Array.from(files), urls).map(([file, url]) => {
    return new Promise((resolve, reject) => {
      customAxios
        .put(url, file, {
          cancelToken: cancelTokenSource.token,
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: progressCallback(dispatch, file)
        })
        .then(() => createImage(studyId, file, url, imageToReplace))
        .then(resolve)
        .catch((thrown: AxiosError) => {
          axios.isCancel(thrown)
            ? reject(`Cancelled upload of ${file.name} to ${url}`)
            : reject(`Error uploading ${file.name} to ${url}`);
        });
    });
  });
}

const confirmPromise = (promise: Promise<any>) => {
  // Wrap a promise so the user will be asked to confirm if they navigate away while it's pending
  const confirmNavigate = (event: BeforeUnloadEvent) => {
    // Old style, used by e.g. Chrome
    // eslint-disable-next-line functional/immutable-data
    event.returnValue = true;
    // New style, used by e.g. Firefox
    event.preventDefault();
    return true;
  };
  window.addEventListener("beforeunload", confirmNavigate);
  return promise.finally(() => {
    window.removeEventListener("beforeunload", confirmNavigate);
  });
};

export async function uploadSampleDataFile(
  file: File,
  sampleDataRequest: SampleDataUploadResponse,
  cancelTokenSource: CancelTokenSource
): Promise<SampleDataImportResults> {
  return confirmPromise(
    new Promise((resolve, reject) =>
      Promise.all(makeUploadSampleDataPromises(file, sampleDataRequest, cancelTokenSource))
        .then(r => {
          resolve(r);
        })
        .catch(error => {
          return reject(pathOr("File upload failed", errorMsgPath, error));
        })
    )
  );
}

export async function uploadFiles(
  studyId: StudyId,
  files: FileList,
  urls: SignedURLs,
  progressCallback: (dispatch: AppDispatch, file: File) => (progressEvent: ProgressEvent) => void,
  imageToReplace: Image | null,
  cancelTokenSource: CancelTokenSource
): Promise<string> {
  return confirmPromise(
    new Promise((resolve, reject) =>
      Promise.all(
        makeUploadPromises(
          dispatch,
          studyId,
          files,
          urls,
          progressCallback,
          imageToReplace,
          cancelTokenSource
        )
      )
        .then(() => resolve("All files uploaded successfully!"))
        .catch(error => {
          return reject(pathOr("File upload failed", errorMsgPath, error));
        })
    )
  );
}

export async function updateStudy(
  studyId: StudyId,
  name?: UpdateValueWithReasonForChange<StudyFormFields["name"]>,
  sponsor?: UpdateValueWithReasonForChange<StudyFormFields["sponsor"]>,
  protocolIds?: UpdateValueWithReasonForChange<StudyFormFields["protocolIds"]>,
  visitIds?: UpdateValueWithReasonForChange<StudyFormFields["visitIds"]>,
  indications?: UpdateValueWithReasonForChange<StudyFormFields["indications"]>,
  segments?: UpdateValueWithReasonForChange<StudyFormFields["segments"]>,
  anatomicalSegments?: UpdateValueWithReasonForChange<StudyFormFields["anatomicalSegments"]>,
  modality?: UpdateValueWithReasonForChange<StudyFormFields["modality"]>,
  uploaders?: UpdateValueWithReasonForChange<StudyFormFields["uploaders"]>,
  readers?: UpdateValueWithReasonForChange<StudyFormFields["readers"]>,
  onHold?: UpdateValueWithReasonForChange<StudyFormFields["onHold"]>,
  onHoldReason?: UpdateValueWithReasonForChange<StudyFormFields["onHoldReason"]>,
  hpfAnnotationClasses?: UpdateValueWithReasonForChange<StudyFormFields["hpfAnnotationClasses"]>,
  pointAnnotationClasses?: UpdateValueWithReasonForChange<
    StudyFormFields["pointAnnotationClasses"]
  >,
  freehandAnnotationClasses?: UpdateValueWithReasonForChange<
    StudyFormFields["freehandAnnotationClasses"]
  >
): Promise<Study> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/studies/${studyId}`, {
      name,
      sponsor,
      protocolIds,
      visitIds,
      indications,
      segments,
      anatomicalSegments,
      modality,
      uploaders: uploaders ? updateWithIdsOnly(uploaders) : null,
      readers: readers ? updateWithIdsOnly(readers) : null,
      readonlyUsers: null,
      onHold: onHold,
      onHoldReason: onHoldReason,
      hpfAnnotationClasses,
      pointAnnotationClasses,
      freehandAnnotationClasses
    })
      .then(response => {
        decodeResponse(studyDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        return reject(pathOr("Unable to update study", errorMsgPath, error));
      });
  });
}

export async function fetchOrganizations(): Promise<Organizations> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/organizations", {})
      .then(response => {
        decodeResponse(organizationsDecoder, response.data.organizations)
          .then(resolve)
          .catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch organizations", errorMsgPath, error)));
  });
}

export async function fetchUserStudyAccess(userId: string): Promise<StudiesAccess> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/users/${userId}/studyAccess`, {})
      .then(response => {
        decodeResponse(studiesAccessDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch user study access", errorMsgPath, error)));
  });
}

export async function fetchCaseSummaries(
  studyId: UUID,
  searchText: string
): Promise<CaseSummaries> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/cases?studyId=${studyId}&searchText=${searchText}`)
      .then(response =>
        decodeResponse(caseSummariesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch cases", errorMsgPath, error)));
  });
}

export async function fetchUsers(
  name?: string,
  role?: string,
  registered?: boolean,
  exclude?: ReadonlyArray<UUID>
): Promise<Users> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users", {
      role,
      name,
      registered,
      exclude
    })
      .then(response =>
        decodeResponse(usersDecoder, response.data.users).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch users", errorMsgPath, error)));
  });
}

export async function fetchUserSummaries(
  name?: string,
  filterByStudyId?: UUID
): Promise<UserSummaries> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/users/list", {
      name,
      filterByStudyId
    })
      .then(response => {
        decodeResponse(userSummariesDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch summaries list", errorMsgPath, error)));
  });
}

export async function fetchUsersInStudy(
  studyId: UUID,
  roleIdFilter: UUID | null
): Promise<UsersInStudy> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/users/inStudy/${studyId}`, { roleIdFilter: roleIdFilter })
      .then(response => {
        decodeResponse(usersInStudyDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch users in study", errorMsgPath, error)));
  });
}

export async function fetchConfig(): Promise<Config> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/config")
      .then(response => decodeResponse(configDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to connect to server", errorMsgPath, error)));
  });
}

export async function fetchCase(caseId: UUID): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/cases/${caseId}`)
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch case", errorMsgPath, error)));
  });
}

export async function fetchImage(imageId: UUID): Promise<ImageWithAnnotations> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/images/${imageId}`)
      .then(response =>
        decodeResponse(imageWithAnnotationsDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch image", errorMsgPath, error)));
  });
}

export async function fetchImages(
  studyId: UUID,
  name: string | null,
  hasQueries: boolean | null,
  isUnassigned: boolean | null
): Promise<ImageListViews> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/images`, {
      name,
      hasQueries,
      isUnassigned
    })
      .then(response =>
        decodeResponse(imageListViewArrayDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch images for study", errorMsgPath, error)));
  });
}

export async function searchStudyImages(
  studyId: UUID,
  caseId: UUID | null,
  name: string | null,
  exclude: ReadonlyArray<UUID>
): Promise<Images> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/cases/images`, {
      id: caseId,
      name,
      exclude
    })
      .then(response =>
        decodeResponse(imageArrayDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch images for case", errorMsgPath, error)));
  });
}

export async function fetchCases(
  studyId: UUID,
  procId?: string,
  status?: CaseStatus,
  assignee?: string
): Promise<CasesAndCounts> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/cases`, {
      procId,
      status,
      assignee
    })
      .then(response =>
        decodeResponse(caseAndCountsArrayDecoder, response.data.cases).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr("Unable to fetch cases for study", errorMsgPath, error)));
  });
}

export async function fetchStudy(studyId: UUID): Promise<StudyView> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}`)
      .then(response => decodeResponse(studyViewDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr(`Unable to fetch study ${studyId}`, errorMsgPath, error)));
  });
}

export async function updateUser(
  id: string,
  firstName?: string,
  lastName?: string,
  organizationId?: string
): Promise<User> {
  return new Promise((resolve, reject) => {
    putHisto(`/api/users/${id}`, {
      firstName: firstName || null,
      lastName: lastName || null,
      organizationId: organizationId || null
    })
      .then(response => decodeResponse(userDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to update user", errorMsgPath, error)));
  });
}

export async function fetchUserById(id: string): Promise<User> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/users/${id}`, {})
      .then(response => decodeResponse(userDecoder, response.data.user).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to fetch user by Id", errorMsgPath, error)));
  });
}

export async function moveImageToStudyApi(
  imageId: UUID,
  studyId: UpdateValueWithMaybeReasonForChange<UUID>
): Promise<Image> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/study`, {
      ...studyId
    })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr(`Unable to move image to new study.`, errorMsgPath, error)));
  });
}

export async function passQcForImage(imageId: UUID): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/pass-qc`)
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr(`Unable to update image status.`, errorMsgPath, error)));
  });
}

export async function moveImageToCaseApi(
  imageId: UUID,
  caseId: UpdateValueWithMaybeReasonForChange<UUID>
): Promise<Image> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/case`, {
      ...caseId
    })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr(`Unable to move image to new case.`, errorMsgPath, error)));
  });
}

export async function copyImage(imageId: UUID, duplicateImageName: string): Promise<Image> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/images/${imageId}/copy`, {
      name: duplicateImageName
    })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error => reject(pathOr("Unable to copy image", errorMsgPath, error)));
  });
}

export async function updateImageApi(
  imageId: UUID,
  imageName?: UpdateValueWithReasonForChange<string | null>,
  accessionNumber?: UpdateValueWithReasonForChange<string | null>,
  biopsyLocation?: UpdateValueWithReasonForChange<string | null>
): Promise<Image> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}`, { imageName, accessionNumber, biopsyLocation })
      .then(response => decodeResponse(imageDecoder, response.data).then(resolve).catch(reject))
      .catch(error =>
        reject(
          pathOr("Unable to update image. Must include reason for change.", errorMsgPath, error)
        )
      );
  });
}

export async function revertCaseStatusApi(
  histoCaseId: UUID,
  status: UpdateValueWithMaybeReasonForChange<CaseStatus>
): Promise<AdminCaseWithImagesAndReaders> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}/status/back`, { ...status })
      .then(response =>
        decodeResponse(adminCaseWithImagesAndReadersDecoder, response.data)
          .then(resolve)
          .catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(
            `Unable to set status of case back to ${formatCaseStatus(
              status.value
            )}. Must set new status and include reason for change.`,
            errorMsgPath,
            error
          )
        )
      );
  });
}

export async function forwardCaseStatus(histoCaseId: UUID): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}/status/forward`)
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to change status of ${histoCaseId}`, errorMsgPath, error))
      );
  });
}

export async function assignImageToCaseApi(
  imageId: UUID,
  caseId: UUID,
  reasonForChange: string
): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/assign`, {
      imageId,
      reasonForChange
    })
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => reject(pathOr(`Unable to assign images to case.`, errorMsgPath, error)));
  });
}

export async function editCase(
  histoCaseId: UUID,
  procId?: UpdateValueWithReasonForChange<string>,
  histoProcedureId?: UpdateValueWithReasonForChange<string | null>,
  siteId?: UpdateValueWithReasonForChange<string | null>,
  subjectId?: UpdateValueWithReasonForChange<string>,
  visitId?: UpdateValueWithReasonForChange<string>,
  readers?: UpdateValueWithReasonForChange<ReadonlyArray<UUID>>,
  images?: UpdateValueWithReasonForChange<ReadonlyArray<UUID>>
): Promise<CaseWithImages> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${histoCaseId}`, {
      procId,
      histoProcedureId,
      siteId,
      subjectId,
      visitId,
      readers,
      images
    })
      .then(response =>
        decodeResponse(caseWithImagesDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to edit case. Reason for change is required.`, errorMsgPath, error))
      );
  });
}

export async function toggleImageVisibility(imageId: UUID): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/images/${imageId}/toggle-image-visibility`)
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to hide image for reader for image ${imageId}`, errorMsgPath, error))
      );
  });
}

export async function downloadAudit(
  reportFormat: string,
  studyData?: ReportStudyData,
  userData?: ReportUserData
): Promise<void> {
  const studyId = studyData && studyData.id ? studyData.id : undefined;
  const studySlug = studyData ? `${studyData.name.replace(/\s/g, "")}_` : "";

  const userId = userData && userData.id ? userData.id : undefined;
  const userSlug = userData ? `${userData.name.replace(/\s/g, "")}_` : "";

  const now = new Date();
  const dateSlug = now.toLocaleDateString(LOCALE);
  const timeSlug = now.toLocaleTimeString(LOCALE, { hour12: false }).replace(/:/g, "_");
  const tzSlug = Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/\//g, "_");

  return new Promise((resolve, reject) => {
    (reportFormat == "pdf"
      ? fetchHistoBlob(`/api/audit-trail`, { reportFormat, studyId, userId })
      : fetchHisto(`/api/audit-trail`, { reportFormat, studyId, userId })
    )
      .then(response => {
        return resolve(
          saveAs(
            new Blob([response.data], {
              type: reportFormat == "pdf" ? "application/pdf" : "text/csv;charset=utf-8"
            }),
            `${studySlug}${userSlug}AuditTrailReport_${dateSlug}_${timeSlug}_${tzSlug}.${reportFormat}`
          )
        );
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch audit trail ${reportFormat}`, errorMsgPath, error))
      );
  });
}

export async function downloadReportCsv(
  reportType: ReportType,
  rsd: ReportStudyData | null,
  ud: ReportUserData | null,
  dateFilter?: DateFilter
): Promise<void> {
  const reportUrl =
    reportType === "image-details" || reportType === "hpf-annotations"
      ? rsd?.id
        ? `/api/report/${rsd.id}/${reportType}?${qs.stringify(dateFilter || {})}`
        : `/api/report/${reportType}?${qs.stringify(dateFilter || {})}`
      : `/api/report/${reportType}?${qs.stringify(
          { ...dateFilter, studyId: rsd?.id, userId: ud?.id } || {}
        )}`;
  return new Promise((resolve, reject) => {
    fetchHisto(reportUrl)
      .then(response => {
        const reportBaseName: string = rsd ? rsd.name.replace(/\s/g, "") : "Global";
        return resolve(
          saveAs(
            new Blob([response.data], { type: "text/csv;charset=utf-8" }),
            `${reportBaseName}_${
              reportType === "image-details" ? "ImageDetailsReport" : reportType
            }_${new Date().toLocaleDateString(LOCALE)}.csv`
          )
        );
      })
      .catch(error => reject(pathOr(`Unable to fetch report ${reportType}`, errorMsgPath, error)));
  });
}

export async function deleteImage(
  imageId: UpdateValueWithMaybeReasonForChange<UUID>
): Promise<void> {
  return new Promise((resolve, reject) => {
    // NOTE: A payload in a DELETE request has no defined semantics and
    // axios.delete does not support a request body so POST is used here
    postHisto(`/api/images/${imageId.value}`, { ...imageId })
      .then(response => resolve(response.data))
      .catch(error =>
        reject(
          pathOr("Could not archive image. Must include reason for change.", errorMsgPath, error)
        )
      );
  });
}

export async function archiveCaseApi(caseId: UUID, reasonForChange: string): Promise<void> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/archive`, { caseId, reasonForChange })
      .then(response => resolve(response.data))
      .catch(error =>
        reject(
          pathOr("Could not archive case. Must include reason for change.", errorMsgPath, error)
        )
      );
  });
}

export async function putCaseOnHoldApi(caseId: UUID, reasonForChange: string): Promise<void> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/status/on_hold`, { caseId, reasonForChange })
      .then(response => resolve(response.data))
      .catch(error => reject(pathOr("Could not put case on hold.", errorMsgPath, error)));
  });
}

export async function removeCaseHoldApi(caseId: UUID, reasonForChange: string): Promise<void> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/status/off_hold`, { caseId, reasonForChange })
      .then(response => resolve(response.data))
      .catch(error => reject(pathOr("Could not remove case hold.", errorMsgPath, error)));
  });
}

export async function downloadImage(imageId: UUID): Promise<GetDownloadResponse> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/signed-urls/image/${imageId}`)
      .then(response => {
        decodeResponse(getDownloadResponseDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Could not download image.", errorMsgPath, error)));
  });
}

export async function suggestReaders(studyId: UUID, subjectId: string): Promise<ReadersStudyStats> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/users/suggest-readers`, { studyId, subjectId })
      .then(response => {
        decodeResponse(readersStudyStatsDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch suggested readers", errorMsgPath, error)));
  });
}

export async function searchStudyReaders(studyId: UUID, name: string): Promise<ReadersStudyStats> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/studies/${studyId}/users`, { name })
      .then(response => {
        decodeResponse(readersStudyStatsDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to search study readers", errorMsgPath, error)));
  });
}

/*
 * Fetch API image data (eg. "metadata" images such as label and macro).
 */
export function fetchMetadataImageApi<T extends MetadataImageType>(
  imageUri: string,
  metadataType: T
): Promise<MetadataImage<T>> {
  return new Promise((resolve, reject) => {
    axios
      .get(imageUri, { responseType: "arraybuffer" })
      .then(response => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        resolve({
          metadataType,
          imageData: "data:;base64," + base64
        });
      })
      .catch(() => reject("Error fetching metadata image data"));
  });
}

export async function addComment(
  caseId: UUID,
  imageId: UUID | null,
  comment: string
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/cases/${caseId}/comment`, { caseId, imageId, comment })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to add case comment for case ${caseId}`, errorMsgPath, error))
      );
  });
}

// image management

export interface ImageManagementStudyFilter {
  searchText: string;
  selectedIds: Array<UUID>;
  selected: Array<Study>;
}

export type DateFilterOptions = "Yesterday" | "Last7" | "Last14" | "Last60" | "AllTime";

export function formatDateFilterOptions(option: DateFilterOptions): string {
  switch (option) {
    case "Yesterday":
      return "Yesterday";
    case "Last7":
      return "Last 7 Days";
    case "Last14":
      return "Last 14 Days";
    case "Last60":
      return "Last 60 Days";
    case "AllTime":
      return "All Time";
    default:
      return "-";
  }
}

export interface ImageManagementDateSearchFilter {
  dateRange: DateFilterOptions | null;
  customRange: string | null;
}

export interface ImageManagementSearchFilters {
  studyFilter: ImageManagementStudyFilter | null;
  dateFilter: ImageManagementDateSearchFilter | null;
  imageStatusFilter: Array<ImageStatusType> | null;
}

export interface ImageManagementCaseSearchFilters {
  studyFilter: ImageManagementStudyFilter | null;
  dateFilter: ImageManagementDateSearchFilter | null;
  caseStatusFilter: Array<CaseStatus> | null;
}

export interface ImageManagementSearch {
  searchText: string;
  filters: ImageManagementSearchFilters;
}

export interface ImageManagementCaseSearch {
  searchText: string;
  filters: ImageManagementCaseSearchFilters;
}

export async function fetchImageManagementImagesTab(
  search: ImageManagementSearch
): Promise<ImageManagementRecords> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/imageManagement/images`, {
      ...search
    })
      .then(response =>
        decodeResponse(imageManagementRecordArrayDecoder, response.data.results)
          .then(resolve)
          .catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to fetch image management images tab results`, errorMsgPath, error))
      );
  });
}

export async function fetchImageManagementCasesTab(
  search: ImageManagementCaseSearch
): Promise<ImageManagementCaseRecords> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/imageManagement/cases`, {
      ...search
    })
      .then(response =>
        decodeResponse(imageManagementCaseRecordArrayDecoder, response.data.results)
          .then(resolve)
          .catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to fetch image management images tab results`, errorMsgPath, error))
      );
  });
}

export async function fetchImageManagementImagesTabCsvApi(
  search: ImageManagementSearch
): Promise<void> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/imageManagement/images?reportFormat=csv`, {
      ...search
    })
      .then(response => {
        const reportImageBaseName: string = "image-mgmt-images";
        return resolve(
          saveAs(
            new Blob([response.data], { type: "text/csv;charset=utf-8" }),
            `${reportImageBaseName}_${new Date().toLocaleDateString(LOCALE)}.csv`
          )
        );
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch image management images tab results`, errorMsgPath, error))
      );
  });
}

export async function fetchImageManagementCasesTabCsvApi(
  search: ImageManagementCaseSearch
): Promise<void> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/imageManagement/cases?reportFormat=csv`, {
      ...search
    })
      .then(response => {
        const reportCaseBaseName: string = "image-mgmt-cases";
        return resolve(
          saveAs(
            new Blob([response.data], { type: "text/csv;charset=utf-8" }),
            `${reportCaseBaseName}_${new Date().toLocaleDateString(LOCALE)}.csv`
          )
        );
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch image management cases tab results`, errorMsgPath, error))
      );
  });
}

export async function saveEditColumnValueApi(
  imageId: UUID,
  tabName: "images" | "cases",
  columnName: string,
  columnValue: string,
  reasonForChange: string | null
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/imageManagement/${imageId}/editImageColumn`, {
      tabName,
      columnName,
      columnValue,
      reasonForChange
    })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error => {
        return reject(pathOr("Unable to edit column successfully", errorMsgPath, error));
      });
  });
}

export async function fetchImageManagementRecordByImageId(
  imageId: UUID
): Promise<ImageManagementRecords> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/imageManagement/imageRecord/${imageId}`)
      .then(response => {
        decodeResponse(imageManagementRecordArrayDecoder, response.data.results)
          .then(resolve)
          .catch(reject);
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch image management case record by id`, errorMsgPath, error))
      );
  });
}

export async function fetchImageManagementCaseRecordByCaseId(
  caseId: UUID
): Promise<ImageManagementCaseRecords> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/imageManagement/caseRecord/${caseId}`)
      .then(response => {
        decodeResponse(imageManagementCaseRecordArrayDecoder, response.data.results)
          .then(resolve)
          .catch(reject);
      })
      .catch(error =>
        reject(pathOr(`Unable to fetch image management case record by id`, errorMsgPath, error))
      );
  });
}

// queries

export async function fetchQueries(
  queriesFilter: QueryFilter | null
): Promise<QuerySearchDataRecords> {
  return new Promise((resolve, reject) => {
    postHisto("/api/queries", {
      ...queriesFilter
    })
      .then(response => {
        return decodeResponse(querySearchDataDecoder, response.data.queries)
          .then(resolve)
          .catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch queries", errorMsgPath, error)));
  });
}

export async function closeQueryApi(
  queryId: UUID,
  detailedResolutionText: String
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/queries/${queryId}/close`, { queryId, detailedResolutionText })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(pathOr(`Unable to close query for queryId ${queryId}`, errorMsgPath, error))
      );
  });
}

export async function markQueryUnresolvable(
  queryId: UUID,
  unresolvableReasonText: String
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/queries/${queryId}/unresolvable`, { queryId, unresolvableReasonText })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(`Unable to mark query unresolvable for queryId ${queryId}`, errorMsgPath, error)
        )
      );
  });
}

export async function createQuery(
  queryObjectType: QueryObjectType,
  objectId: UUID,
  studyId: UUID | null,
  caseIdArg: UUID,
  category: String,
  categoryOtherText: String | null,
  followUpInDays: number,
  commentText: String | null,
  withRoleId: String | null,
  responseOptionId: String | null,
  childResponseOptionId: String | null
): Promise<ApiResponse> {
  const caseId: UUID | null = caseIdArg == "" ? null : caseIdArg;
  return new Promise((resolve, reject) => {
    postHisto(`/api/queries/new`, {
      queryObjectType,
      objectId,
      studyId,
      caseId,
      organizationId: null,
      category,
      categoryOtherText,
      followUpInDays,
      withRoleId,
      responseOptionId,
      childResponseOptionId,
      commentText
    })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(
            `Unable to create query for object type ${queryObjectType} object id ${objectId}`,
            errorMsgPath,
            error
          )
        )
      );
  });
}

export async function newQueryFollowUpReminder(
  queryId: UUID,
  queryReminderId: UUID,
  followUpInDays: number
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/queries/${queryId}/reminder`, { queryId, queryReminderId, followUpInDays })
      .then(response =>
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject)
      )
      .catch(error =>
        reject(
          pathOr(`Unable to create query reminder for queryId ${queryId}`, errorMsgPath, error)
        )
      );
  });
}

export async function addQueryComment(queryId: UUID, commentText: string): Promise<any> {
  return new Promise((resolve, reject) => {
    postHisto(`/api/queries/${queryId}/comment`, { queryId, commentText })
      .then(response => {
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        reject(
          pathOr(`Unable to add comment to query for queryId ${queryId}`, errorMsgPath, error)
        );
      });
  });
}

export async function markQueryAsResolved(
  queryId: UUID,
  resolutionOption: string,
  commentText: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    patchHisto(`/api/queries/${queryId}/resolve`, { queryId, resolutionOption, commentText })
      .then(response => {
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        reject(
          pathOr(`Unable to mark the query as resolved for queryId ${queryId}`, errorMsgPath, error)
        );
      });
  });
}

export async function fetchAllQueriesByCase(caseId: UUID): Promise<any> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/queries/case/${caseId}`, {})
      .then(response => {
        decodeResponse(queryDetailsArrayDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        reject(pathOr(`Unable to fetch queries for caseId ${caseId}`, errorMsgPath, error));
      });
  });
}

export async function fetchAllQueriesByImage(imageId: UUID): Promise<any> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/queries/image/${imageId}`, {})
      .then(response => {
        decodeResponse(queryDetailsArrayDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => {
        reject(pathOr(`Unable to fetch queries for imageId ${imageId}`, errorMsgPath, error));
      });
  });
}

export async function fetchQueryMetadata(studyId: UUID | null): Promise<any> {
  return new Promise((resolve, reject) => {
    fetchHisto(`/api/queries/metadata`, { studyId })
      .then(response => {
        decodeResponse(queryMetadataDecoder, response.data.metadata).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch query metadata", errorMsgPath, error)));
  });
}

export async function fetchRoles(): Promise<Roles> {
  return new Promise((resolve, reject) => {
    fetchHisto("/api/permissions/roles", {})
      .then(response => {
        decodeResponse(rolesDecoder, response.data.roles).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to fetch permission roles", errorMsgPath, error)));
  });
}

export async function addStudyAccess(
  studyId: string,
  userId: string,
  roleId: string
): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto("/api/users/studies/add", {
      studyId,
      userId,
      roleId
    })
      .then(response => {
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to add study access", errorMsgPath, error)));
  });
}

export async function removeStudyAccess(userId: string, studyId: string): Promise<ApiResponse> {
  return new Promise((resolve, reject) => {
    patchHisto("/api/users/studies/remove", {
      userId,
      studyId
    })
      .then(response => {
        decodeResponse(apiResponseDecoder, response.data).then(resolve).catch(reject);
      })
      .catch(error => reject(pathOr("Unable to remove study access", errorMsgPath, error)));
  });
}
